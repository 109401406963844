<template>
    <div>
        <div class="container">
            <el-row :gutter="20">
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>用户数</span>
                            <span>{{ totalObj.num1}}</span>
                        </div>
                    </div>    
                </el-col>
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>家长数</span>
                            <span>{{ totalObj.num3}}</span>
                        </div>
                    </div>    
                </el-col>
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>档案数</span>
                            <span>{{ totalObj.num2}}</span>
                        </div>
                    </div>    
                </el-col>
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>账户数</span>
                            <span>{{ totalObj.num4 }}</span>
                        </div>
                    </div>    
                </el-col>
                
            </el-row>
        </div>
        <!-- <div v-else class="container">
            <el-row :gutter="20">
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>总学生数</span>
                            <span>28912</span>
                        </div>
                    </div>    
                </el-col>
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>总家长数</span>
                            <span>30202</span>
                        </div>
                    </div>    
                </el-col>
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>总档案数</span>
                            <span>104257</span>
                        </div>
                    </div>    
                </el-col>
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>筛查档案数</span>
                            <span>101042</span>
                        </div>
                    </div>    
                </el-col>
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>外部档案数</span>
                            <span>3215</span>
                        </div>
                    </div>    
                </el-col>
                <el-col :span="4">
                    <div class="box-white top-box">
                        <div class="box-top">
                            <span>来源学校数</span>
                            <span>54</span>
                        </div>
                    </div>    
                </el-col>
                
            </el-row>
        </div> -->
        

    </div>
    
</template>
<script>
    export default {
        data() {
            return {
                user:{},
                updataStatus: 1,
                totalObj:{
                    "jzmun": ""
                },
            }
        },
        created() {
            this.user = JSON.parse(sessionStorage.getItem("userInfo"))
        },
        mounted() {
            this.getUsersTotal();
        },
        methods: {
            getUsersTotal(){
                 // 用户数统计
                 this.$api.count.count().then(res => {
                    console.log("getUsersTotal=>",res)
                     this.totalObj = res.obj
                })
            },
        },
    }
</script>
<style scoped>
    .container {
        width: 100%;
        padding: 20px;
        user-select: none;
    }

    .box-white {
        background-color: #ffffff;
        border-radius: 0.1vw;
        padding: 10px;
    }

    .box-white>span {
        font-size: 12px;
        color: #666;
        margin-bottom: 5px;
    }

    .box-top>span {
        font-size: 12px;
        color: #666;
        margin-bottom: 5px;
    }

    .box-bottom>span {
        font-size: 12px;
        color: #666;
        margin-bottom: 5px;
    }

    .top-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .box-top{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .box-bottom {
        display: flex;
        justify-content: center;
    }

    .charts {
        margin-top: 20px;
    }

    .height300 {
        height: 300px;
        margin-bottom: 20px;
    }

    .height406 {
        height: 406.3px;
        margin-bottom: 20px;
    }

    .chart-title {
        height: 30px;
        font-size: 12px;
        font-weight: bold;
        color: #333333;
    }

    .updata-title>span {
        margin-right: 15px;
        cursor: pointer;
    }

    .gray {
        color: #999999;
    }

    #usersChart,
    #usersEyeChart,
    #usersEyeChart1,
    #updataChart,
    #childAccountChart,
    #dangerAccountChart,
    #parAccountChart {
        width: 100%;
        height: calc(100% - 30px);
    }
</style>