<template>
  <div class="vip" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">

    <div>
          <UserData></UserData>
    </div>
    <div class="teb_header">
      <div class="hang">  
        <div style=" display: flex;">
          <el-input v-model="cname" placeholder="用户名搜索"  class="ss"  :size="tabsize"  ></el-input>
          <el-input v-model="phone" placeholder="手机号"  class="ss"  :size="tabsize"  ></el-input>
          <el-select  clearable size="mini" v-model="mid" placeholder="渠道" >
                <el-option
                v-for="(item,index) in arr"
                :key="index"
                :label="item.cname"
                :value="item.id">
            </el-option>
            </el-select>
          <el-button icon="el-icon-search" type="success" :size="tabsize" @click="postlist()">搜索</el-button>
          <el-button :size="tabsize" type="primary" @click="exp()">导出</el-button>
        </div>
        <el-button :size="tabsize" type="primary" @click="add()">添加用户</el-button>
      </div>
    </div>

<div>
    <el-table
      ref="multipleTable"
      border
      height="67vh"
      :size="tabsize"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55">    </el-table-column>
      <el-table-column  prop="cname" align="center"  label="用户名" width="120" ></el-table-column>
      <el-table-column  prop="sex"  align="center"  label="性别" width="60" >
            <template slot-scope="scope" >
                <span style="margin-left: 10px">{{ scope.row.sex | sex }}</span>
            </template>
      </el-table-column>
      <el-table-column   align="center"  label="年龄" width="100" >
          <template slot-scope="scope">
                <span style="margin-left: 10px">{{ formatAge(scope.row.age) }}</span>
          </template>
      </el-table-column>
      <el-table-column  align="center"  label="城市" width="200" >
          <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.add1+scope.row.add2+scope.row.add3 }}</span>
          </template>
      </el-table-column>
      <el-table-column  prop="phone"    align="center"   label="联系方式"  width="150" ></el-table-column>
      <el-table-column  prop="parents"  align="center"   label="家长" width="120" ></el-table-column>
      <el-table-column  prop="num"      align="center"   label="档案数" width="80" ></el-table-column>
      <el-table-column    align="center"   label="关注时间" width="150" >
          <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.ctime | timestampToTime }}</span>
         </template>
      </el-table-column>
      <el-table-column  v-if="user.username !== '第二师铁门关市视力档案管理系统'"    align="center"   label="账户"  >
         <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.utype | utype}}</span>
         </template>
      </el-table-column>
      <el-table-column  v-else    align="center"   label="所属渠道"  >
         <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.utype | utype}}</span>
         </template>
      </el-table-column>
      <el-table-column label="操作"  show-overflow-tooltip align="center" >
            <template slot-scope="scope">
              <el-button :size="tabsize"  type="danger"
                @click.native.prevent="handlelook(scope.$index,  scope.row)" >详情
              </el-button>
            </template>
      </el-table-column>
    </el-table>
</div>

<el-dialog v-if="formShow" style="text-align: center;"  :title="titleTxt" :visible.sync="formShow" width="700px">
        <el-form label-position="right" label-width="96px" ref='ruleForm' inline :rules='rules' :model="ruleForm">
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="姓名:"  prop="cname">
                          <el-input  v-model="ruleForm.cname" ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="身份证号:" >
                          <el-input  v-model="ruleForm.card" maxlength="18"></el-input>
                      </el-form-item>
                  </el-col>
              </el-row>

              <el-row>
                  <el-col :span="12">
                      <el-form-item label="性别:"  prop="sex" style="margin-left:14px">
                          <el-select v-model="ruleForm.sex" placeholder="请选择"  style="width:174px">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="家长姓名:" prop="pname" >
                          <el-input  v-model="ruleForm.pname" ></el-input>
                      </el-form-item>
                  </el-col> 
              </el-row>

              <el-row>
              <el-col :span="12">
                    <el-form-item label="关系:" style="margin-left:14px" prop="parents">
                          <el-select v-model="ruleForm.parents" placeholder="请选择"  style="width:174px">
                            <el-option
                              v-for="item in options1"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                    </el-form-item>
              </el-col>


              <el-col :span="12">
                      <el-form-item label="家长手机:" prop="phone1"> 
                          <el-input  maxlength="11" oninput="value=value.replace(/[^\d.]/g,'')"  v-model="ruleForm.phone1" ></el-input>
                      </el-form-item>
                  </el-col>
              </el-row>

              <el-row>
                  <el-col :span="12">
                      <el-form-item label="出生日期:"  prop="birthdata" >
                          <el-date-picker  v-model="ruleForm.birthdata" :picker-options="pickerOptions" type="date" placeholder="选择日期" style="width:174px"> </el-date-picker>
                      </el-form-item>
                  </el-col>
              </el-row>
          

              
              <p class="btns">
                <el-button  :size="tabsize" @click="formShow = false" >取消</el-button>
                <el-button  :size="tabsize" type="primary"  @click="onSubmit('ruleForm')">提交</el-button>
              </p>
        </el-form>
        
      </el-dialog>




    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getBaseUrl } from "../../../requset/http";
import { timestampToTime } from "./formdate";
import UserData from './UserData';
export default {
  components: {
            UserData
        },
  computed: {
    tabsize() {
      this.$setTableHeight();
      return this.$store.state.tabsize;
    },
  },  filters: {
      capitalize: function (value) {
        switch (value) {
          case "1":
            value = "销售模式";
            break;
          case "2":
            value = "合作模式";
            break;
          case "3":
            value = "打版模式";
            break;
        }
        return value;
      }
},
  data() {
    return {
      user:{},
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
      },
      ruleForm: {
            cname: '',
            card: '',
            sex:'',
            age:"",
            pname: '',
            phone1:'',
            parents:'',
            birthdata:"",
          }, 
          titleTxt:"添加账户",
          options: [{
          value: '1',
          label: '男'
        }, {
          value: '2',
          label: '女'
        }],
        options1:[],
        rules: {
          cname: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
          ],
          sex: [
            {  required: true, message: '请选择性别', trigger: 'change' }
          ],
          age: [
            { required: true,  type: 'number', message: '年龄不能为空'},
          ],
          pname: [
            { required: true, message: '请输入家长姓名', trigger: 'change' }
          ],
          phone1: [
            { required: true, message: '请输入手机号',trigger: 'change'},
          ],
          parents: [
            { required: true, message: '请选择关系', trigger: 'change' }
          ],
          birthdata: [
            { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
          ],
        },

        formShow: false,//用于控制是否显示表单 true显示
        id: "",
        sort: "id",
        order: "desc",
        currentPage: 1, //默认显示第一页
        pageSize: 10, //默认每页显示10条
        totalNum: "", //总页数
        time: [],
        ctime:"",
        cname: "",
        tableData: [],
        mid:"",
        phone:"",
        arr:[],
        userInfo: '',
        loading: true,
    };
  },
  methods: {
    handlelook(index, row) {
      this.$router.push({
        path: "./vipinfo",
        query: { kiid: row.id },
      });
    },

    handleChange(value) {
      // console.log(value);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
      formatAge(val) {
                 return val ? Math.floor(val / 12) + '岁' + ((val % 12) != 0 ? (val % 12) + '个月' : '') : ''
            },
    
        onchange(val) {
      // console.log(val);
      // console.log("我是val");
      if (val) {
        this.stime = val[0];
        this.etime = val[1];
        // console.log(this.stime);
        // console.log(this.etime);
      } else {
        this.stime = "";
        this.etime = "";
      }
    },

    add(){
        this.ruleForm.cname="",
        this.ruleForm.card="",
        this.ruleForm.sex="",
        this.ruleForm.age="",
        this.ruleForm.pname="",
        this.ruleForm.phone1="",
        this.ruleForm.parents="",
        this.ruleForm.birthdata="",
        this.formShow=true;
        this.parents();
    },

onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {      
          this.$api.vipuser.save({
          cname:this.ruleForm.cname,
          card:this.ruleForm.card,
          sex:this.ruleForm.sex,
          age:this.ruleForm.age,
          pname:this.ruleForm.pname,
          phone:this.ruleForm.phone1,
          parents:this.ruleForm.parents,
          birthdata:timestampToTime(this.ruleForm.birthdata)
      })
        .then((res) => {
          // console.log(res);
          // console.info("save");

          if (res.status != 0) {
            this.$notify({
              title: "成功",
              message: res.msg,
              type: "success",
            });
             this.postlist();
             this.formShow=false;
          } else {
            this.$notify({
              title: "警告",
              message: res.msg,
              type: "warning",
            });
          }
       
        })
        .catch((error) => {
          console.log(error);
        });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    //获取关系
    parents(){
        this.$api.vipuser.parents({
      })
        .then((res) => {
          this.options1=res.obj;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    postlist() {
      this.loading = true
      this.$api.vipuser.postlist({
        "jPage.pageNum": this.currentPage,
        "jPage.pageSize": this.pageSize,
        "jPage.sort": this.sort,
        "jPage.order": this.order,
        cname: this.cname,
        phone: this.phone,
        mid:this.mid,
      })
        .then((res) => {
          this.tableData = res.obj.list; //获取数据列表
          console.info('list==>',res);
          this.totalNum = res.obj.totalPage; // 总页数
          this.tableData.length = res.obj.totalRow; //总条数
          this.currentPage = res.obj.pageNumber; //当前页码数
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error);
        });
    },
    // 分页大小
    handleSizeChange(val) {
      this.loading = true;
      this.pageSize = val;
      this.currentPage = 1; // 选择分页大小后跳转第一页
      this.postlist();
      console.log("分页大小" + val);
    },
    // 第几页
    handleCurrentChange(val) {
      this.loading = true;
      this.currentPage = val;
      this.postlist();
    },
    exp(){
      if(this.cname != '' || this.phone != '' || this.mid != '') {
        window.open(getBaseUrl()+'/pc/user/src/userExport?cname='+this.cname+'&phone='+this.phone+'&mid='+this.mid+'&userInfo='+this.userInfo)
      } else {
        window.open(getBaseUrl()+'/pc/user/src/userExport?userInfo='+this.userInfo)
      }
      },
      getSelList() {
        this.$api.vipuser.getSelList({}).then(res => {
          // console.log(res)
          this.arr = res.obj
        })
      },
      
  },
  mounted() {
    this.postlist();
    this.getSelList()
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo')).username
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("userInfo"))
  }
};
</script>

<style scoped>
.vip {
  position: relative;
  width: 98%;
  height: 98%;
  margin: 1%;
}

h3{
  width: 100%;
}
.ss {
  margin-right: 1.5vw;
  width: 8vw !important;
  height: 1vw !important;
}

.hang {
  display: flex;
  justify-content: space-between;
  align-items:center;
}

.block {
  margin:0 1vw;
}
.el-select {
  margin-right: 1vw;
}

#hccode {
  margin-left: 1vw;
}

.hang2 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}


.teb_header .el-input {
  width: auto;
  margin-left: 20px;
}

.el-table td {
  padding: 5px 0;
}

.el-pagination {
  float: right;
  margin-top: 10px;
}

.el-dialog__wrapper/deep/.el-dialog__header {
  text-align: center;
}

</style>
